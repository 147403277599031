// This component is built by Prathamesh - formatted by Raghuram
// TODO: Needs to remove unused things and cleanup styled components
import {
  Dispatch,
  SetStateAction,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ISearchItem } from "../../../../../interfaces/hooks";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import { useGetAssetMetadata } from "../../../../../api/hooks";
import Arrow from "../../../../../assets/icons/arrow.svg";
import { history } from "../../../../../utils/history";
import { appConfig, userActivityEvents } from "../../../../../config";
import FallBack from "../../../Fallback";
import { useLocation, useParams } from "react-router-dom";
import { trackUserAction } from "../../../../../analytics";
import SolutionSearchWidgetToolbar from "../SolutionSearchWidgetToolbar";
import { AssetsContext } from "../../../../../context";
import {
  DropdownItemArrow,
  DropdownListContainer,
  DropdownListItem,
  DropdownListItems,
  DropdownListWrapper,
  DropdownGroupName,
  SearchListHead,
  BreakLine,
  DropdownItemsContainer,
  DropdownListItemLabel,
  DropdownGroupWrapper,
  SearchDropdownListMain,
  SearchDropdownListContainer,
} from "./styled";
import { campaignSearchItems } from "../../../../../config/campaignConfig";

//searchText=It's value access through autosearchcomplete
//searchValue=It's value access directly the targeted one
interface ISearchDropdownList {
  isSolutionLibrary?: boolean;
  searchItems: ISearchItem[] | undefined;
  searchText: string;
  searchValue: string;
  isDropdownListOpen: boolean;
  SearchInProgress: boolean;
  setIsDropdownListOpen: Dispatch<SetStateAction<boolean>>;
}
interface IMetaData {
  label: string;
  object: string;
  slug: string;
}

const {
  pageSpecificConfigs: {
    assets: { parentUrl, pathUrl, staticAssetMapper },
    solution,
    campaign,
    campaignUser,
  },
} = appConfig;
const parseMetaData = (data: IMetaData[] | undefined) => {
  return data?.map((item) => {
    return {
      label: item.label,
      object: item.object,
      slug: item.slug,
    };
  });
};

const SearchDropdownList = ({
  isSolutionLibrary,
  searchItems,
  searchText,
  searchValue,
  isDropdownListOpen,
  SearchInProgress,
  setIsDropdownListOpen,
}: ISearchDropdownList) => {
  const dropdownListRef = useRef(null);
  const location = useLocation();
  const params = useParams();
  const urlParams = new URLSearchParams(location.search);
  useOnClickOutside(dropdownListRef, () => setIsDropdownListOpen(false));
  const [searchLists, setSearchLists]: any = useState({});
  const { data: assetMetadata } = useGetAssetMetadata();
  const { setActiveItemId } = useContext(AssetsContext);
  const parsedMetaData: any = parseMetaData(assetMetadata?.assets);

  const searchListData = () => {
    let searchList: any = {};
    if (searchItems && parsedMetaData) {
      searchItems.forEach((searchItem: any) => {
        const objName = searchItem.object;
        const assetMetaDataObj = parsedMetaData.find(
          (metadata: any) => metadata.object === objName
        );
        if (
          ![
            "relationship_parameter",
            "solution",
            "customer_account",
            "person",
            ...campaignSearchItems,
          ].includes(objName)
        )
          return;
        const slug = assetMetaDataObj?.slug;
        let url = "",
          label = "";
        let title = searchItem[(staticAssetMapper as any)[objName]];
        if (objName === "person") {
          url = `${window.location.origin}/user/contacts/${searchItem.id}`;
          label = "Contacts";
          title = searchItem.full_name;
        } else if (objName === "customer_account") {
          url = `${window.location.origin}/user/accounts/${searchItem.id}`;
          label = "Accounts";
          title = searchItem.name;
        } else if (objName === "solution") {
          url = `${solution.parentUrl}/${searchItem.id}?tab=solution`;
          label = "Pitch";
          title = searchItem.opportunity
            ? `${searchItem.opportunity?.name}: ${searchItem.title}`
            : `${searchItem.title}`;
        } else if (objName === "relationship_parameter") {
          url = `${parentUrl}?tab=${urlParams.get("tab")}&rel=${searchItem.id}`;
          label = searchItem.name;
        } else if (objName === "generic_resource") {
          url = `${parentUrl}/generics/${searchItem.id}`;
          label = searchItem.type;
        } else if (objName === "campaign") {
          url = `${window.location.origin + campaign.parentUrl}/${
            searchItem.id
          }`;
          label = "Campaign";
          title = searchItem?.name || "";
        } else if (objName === "campaign_user") {
          url = `${window.location.origin + campaignUser.parentUrl}/${
            searchItem.external_id
          }`;
          label = "Lead";
          title = searchItem.full_name
            ? `${searchItem.full_name} | ${searchItem.email}`
            : searchItem.email || "";
        } else {
          url = `${(pathUrl as any)[objName]}/${searchItem.id}`;
          label = assetMetaDataObj.label;
        }
        if (!searchList[label]) {
          if (label === "Pitch") {
            searchList = { Pitch: [], ...searchList };
          } else {
            searchList[label] = [];
          }
        }
        searchList[label].push({
          id: searchItem.id,
          object: searchItem.object,
          title,
          slug: slug,
          url: url,
          campaignName: searchItem?.campaign?.name,
          solutions: searchItem?.solutions ? searchItem.solutions : [],
          solution_assets: searchItem?.solution_assets
            ? searchItem?.solution_assets
            : [],
        });
      });
      setSearchLists(searchList);
    }
  };
  useEffect(() => {
    searchListData();
    setIsDropdownListOpen(!!searchItems?.length || !!searchText);
  }, [searchItems, searchText]);

  return (
    <Suspense fallback={<FallBack />}>
      <DropdownListWrapper className="SearchBar__dropdown-list">
        <DropdownListContainer
          className={
            isDropdownListOpen ? "search-dropdown-list" : "hide-dropdown-list"
          }
          ref={dropdownListRef}
        >
          <DropdownListItems>
            {Object.entries(searchLists).map(
              ([groupName, itemArray]: [string, any], index: number, array) => {
                return (
                  <DropdownGroupWrapper key={index}>
                    <DropdownGroupName>{groupName}</DropdownGroupName>
                    <DropdownItemsContainer>
                      {itemArray.map((item: any, i: number) => {
                        return (
                          <DropdownListItem
                            key={i}
                            onClick={() => {
                              setIsDropdownListOpen(false);
                              trackUserAction(
                                userActivityEvents.SEARCH__SEARCH_RESULT_ITEM_CLICKED,
                                {
                                  searchText,
                                  selectedOption: {
                                    title: item.title,
                                    object: item.object,
                                  },
                                }
                              );
                              if (
                                isSolutionLibrary &&
                                item.object === "generic_resource"
                              ) {
                                urlParams.set("from", "libraryModal");
                                setActiveItemId(item?.id.toString());
                                const url = `${
                                  location.pathname
                                }?${urlParams.toString()}`;
                                history.push(url);
                              } else if (
                                ["campaign", "campaign_user"].includes(
                                  item.object
                                )
                              ) {
                                window.open(item.url, "_blank");
                              } else history.push(`${item.url}`);
                            }}
                          >
                            <DropdownListItemLabel>
                              {item.title}
                              {item.campaignName && (
                                <p className="max-w-[200px] text-ellipsis overflow-hidden whitespace-nowrap">
                                  {item.campaignName}
                                </p>
                              )}
                            </DropdownListItemLabel>
                            {isSolutionLibrary ? (
                              <SolutionSearchWidgetToolbar assetItem={item} />
                            ) : (
                              <DropdownItemArrow>
                                <img src={Arrow} alt="Right arrow" />
                              </DropdownItemArrow>
                            )}
                          </DropdownListItem>
                        );
                      })}
                    </DropdownItemsContainer>
                    {index !== array.length - 1 ? <BreakLine /> : null}
                  </DropdownGroupWrapper>
                );
              }
            )}
          </DropdownListItems>
        </DropdownListContainer>
        {!searchItems && searchValue.length ? (
          <></>
        ) : !SearchInProgress &&
          searchValue.length > 2 &&
          searchItems?.length === 0 ? (
          <SearchDropdownListContainer>
            <SearchDropdownListMain>
              <SearchListHead className="No-search">{`No results found for '${searchValue}'`}</SearchListHead>
            </SearchDropdownListMain>
          </SearchDropdownListContainer>
        ) : (
          <></>
        )}
      </DropdownListWrapper>
    </Suspense>
  );
};

export default SearchDropdownList;
