import styled from "@emotion/styled"

export const HubSpotFormContainer=styled.div`  

  #hubspotForm {
  overflow: auto;
  padding: 36px 50px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: auto;
  max-height: max-content;
  width: 466px;
}


#hubspotForm select {
  -webkit-appearance: none !important; 
  -moz-appearance: none !important; 
  appearance: none;
 color :rgba(1, 42, 54,0.8)
}

#hubspotForm select:focus,
#hubspotForm select:active {
    color: black;
}
#hubspotForm select:valid {
  color: black;
  background-color: rgb(232, 240, 254);
}

/* privacy policy styling */
#hubspotForm .hs-richtext {
  color: rgb(1, 42, 54);
  /* text-decoration: underline; */
  font-size: small;
  padding-left: 5px;
  padding-bottom: 10px;
  font-weight: 400;
}
/* styling for error message */
#hubspotForm .hs-error-msgs,
.hs-error-msg {
  color: rgb(1, 42, 54);
  font-weight: bold;
  font-size: 16px;
  /* margin-top: 5px; */
  font-weight: 700;
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
  display: block;
  width: fit-content;
}
.hs-error-msgs {
  margin-bottom: 10px;
}
/* Style the form fields */
#hubspotForm .hs-input {
 width: 100%;
  padding: 15px;
  border: 2px solid slategray;
  border-radius: 5px;
  font-size: 15px;
  border-color: rgb(1, 42, 54);
  border-radius: 30px;
  transition: border 0.3s ease-in-out;
}

#hubspotForm .hs-input::placeholder{
 color:rgba(1, 42, 54, 0.8)
}
/* Add focus effect */
#hubspotForm .hs-input:focus {
  border-color: rgb(205, 220, 223);

  outline: none;
  // box-shadow: 0 0 5px rgba(95, 98, 102, 0.5);
}

/* Style form labels */
#hubspotForm .hs-label {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}

/* Style the submit button */
#hubspotForm input[type="submit"] {
  margin-top: 10px;
  width: 50%; /* Set width to half */
  background: rgba(255, 73, 44, 0.65);
  color: rgb(1, 42, 54);
  font-weight: bold;
  padding: 15px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  display: block; 
  margin: 0; 
  font-size: 18px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 16px;
}


#hubspotForm input[type="submit"]:hover {
  background: rgba(255, 72, 44, 0.378);
}

/* Improve spacing between fields */
#hubspotForm .hs-form-field {
  margin-bottom: 15px;
}



/* modile devices css */
@media (max-width: 480px) {
  #hubspotForm {
    width: 100%;
    max-width: 300px;
    padding: 20px 25px;
  }
  
  #hubspotForm .hs-input {
    padding: 8px 12px;
    font-size: 14px;
  }
  
  #hubspotForm input[type="submit"] {
    width: 70%;
    font-size: 15px;
    padding: 16px 26px;
  }
  
  #hubspotForm .hs-error-msgs,
  .hs-error-msg {
    font-size: 12px;
  }
  
 
  #hubspotForm .hs-richtext {
    
   font-size: x-small;
  }

  #hubspotForm select {
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none;
    color :rgba(1, 42, 54,0.8)
  }
  #hubspotForm select:focus,
  #hubspotForm select:active {
      color: black;
  }
  #hubspotForm select:valid {
    color: black;
    background-color: rgb(232, 240, 254);
}
  
}

`