import { useEffect, useState, useRef } from "react";

interface CounterProps {
  from?: number;
  to: number;
  className?: string;
  suffix?: string;
  speed?: number;
  prefix?: string;
}

const DEFAULT_SPEED = 10;
const DEFAULT_FROM_NUMBER = 0;
const AnimatedNumberCounter: React.FC<CounterProps> = ({
  from = DEFAULT_FROM_NUMBER,
  to,
  className,
  suffix = "",
  speed = DEFAULT_SPEED,
  prefix = "",
}) => {
  const [count, setCount] = useState(from);
  const ref = useRef<HTMLSpanElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          let current = from;
          const step = Math.ceil((to - from) / 60);
          const interval = setInterval(() => {
            current += step;
            if (current >= to) {
              setCount(to);
              clearInterval(interval);
            } else {
              setCount(current);
            }
          }, speed);

          return () => clearInterval(interval);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) observer.current.observe(ref.current);

    return () => observer.current?.disconnect();
  }, [from, to]);

  return (
    <span ref={ref} className={className}>
      {prefix}
      {count}
      {suffix}
    </span>
  );
};

export default AnimatedNumberCounter;
