import { useContext, useState, useEffect } from "react";
import { SolutionsContext } from "../../../context";
import { useLoggedInTeam } from "../../../api/hooks/useLoggedInTeam";
import { HeaderTempletWrapper } from "./styled";
import { watchBreakpointChange } from "../../../utils/deviceWidth";
import HubSpotFormComponent from "../../../components/compositional/Reusable/components/WidgetHubSpotForm/HubSpotForm";
import { SolutionDetailsContext } from "../../../context/SolutionDetailsContext";


const SharedSolutionHeaderSix = () => {
   const { solutionsState, solutionsDispatch, setShareableResObj } =
       useContext(SolutionsContext);
  const [breakpoint, setBreakpoint] = useState<string>("md");
  const loggedInTeamDetails = useLoggedInTeam();
  const { solutionAssetTypes } = useContext(SolutionDetailsContext);
  const [styleData, setStyleData] = useState({
    template: "template_3",
    themes: [
      {
        imports: {
          fonts: [
            "https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap",
          ],
        },
        is_default: true,
        key: "fareye_theme1",
        name: "FarEye Theme 1",
        style: {
          body: {
            content: {
              footer: {},
              header: {
                body: {
                  background:
                    "url('https://storage.googleapis.com/mock_images_for_gp/fareye_background_1.png')",
                },
                navLogo: {
                  borderRadius: "10px",
                  height: "70px",
                },
                title: {
                  fontSize: "2rem",
                  fontWeight: "500",
                  lineHeight: "150%",
                },
              },
              section: {
                body: {},
                navTitle: {},
                title: {
                  color: "white",
                  fontSize: "3rem",
                  fontWeight: "500",
                  padding: "1.5% 2%",
                },
              },
              section1: {
                body: {
                  fontSize: "1.8rem",
                  fontWeight: 300,
                },
                navTitle: {},
                title: {
                  color: "black",
                  fontSize: "2.3rem",
                },
              },
              subSection: {
                body: {
                  content: {
                    h2: {},
                    h3: {},
                    normal: {
                      fontSize: "1.8rem",
                      fontWeight: 300,
                    },
                    title: {},
                  },
                  style: {},
                },
                navTitle: {},
                title: {
                  color: "black",
                  fontSize: "2.3rem",
                },
              },
              heroSection: {
                fontSize: "3rem",
                bgColor: "Blue",
                bgImage:
                  "https://www.feyenschliff.de/wp-content/uploads/2021/05/1213-580%C3%97310.jpg",
              },
            },
            style: {
              fontFamily: "Outfit, sans-serif",
              fontSize: "2.3rem",
            },
          },
        },
      },
    ],
  });
  const [heroStyle, setHeroSTyle] = useState(
    styleData.themes[0].style.body.content.heroSection
  );
  const hubspotFormId = "04564f7d-166e-46d4-ac25-7f310a7dd274";
  const hubspotPortalId = "7721589";

  useEffect(() => {
    watchBreakpointChange(setBreakpoint);
  }, []);

//  console.log(solutionsState, "i am from header");
  return (
    <HeaderTempletWrapper>
      <div className="font-staticFont relative">
        {/* hero section */}
        <div className="relative w-full min-h-screen px-3">
          {/* Background video and overlay */}
          <div className="absolute inset-0 z-0">
            {/* <video
                className="absolute inset-0 w-full h-full object-cover hidden sm:block"
                src="https://fareye.com/uploads/generic/videos/FarEye-Routes.mp4"
                autoPlay
                loop
                muted
                playsInline
              /> */}
            <img
              src={heroStyle.bgImage}
              alt="bg image"
              className="absolute inset-0 w-full h-full object-cover hidden sm:block"
            />
            <div className="hero-gradient absolute inset-0" />
            <img
              className="md:object-cover opacity-40 md:opacity-20 right-0 w-full"
              src="https://fareye.com/assets/images/watermark--hero-wavy-lines.png"
              alt="wave"
            />
          </div>

          {/* Main content container with adjusted padding */}
          <section className="relative z-10 px-2 md:px-32 py-8 md:py-0 h-full">
            <div className="flex flex-col h-full md:flex-row md:items-center md:justify-between md:space-x-8">
              {/* Left side content */}
              <div className="w-full md:w-1/2 pt-4 pb-8 md:py-16">
                {/* Logos container */}
                <div className="flex items-center space-x-4 mb-6 md:mb-10">
                  <img
                    src={loggedInTeamDetails.icon}
                    alt="Seller Logo"
                    className="h-8 md:h-10 rounded-md"
                  />
                  <img
                    src={solutionsState?.solutionObject?.logo}
                    alt="Buyer Logo"
                    className="h-8 md:h-10 rounded-md"
                  />
                </div>

                {/* Main heading */}
                <h1
                  style={
                    breakpoint === "md"
                      ? { fontSize: heroStyle.fontSize || "" }
                      : undefined
                  }
                  className="md:leading-[64px] w-full font-bold mb-4 sm:mb-6 text-2xl md:text-[3rem] text-slate-100"
                >
                  {solutionsState?.solutionObject?.title}
                </h1>

                {/* Bullet points */}
                <ul className="space-y-2 md:space-y-4 lg:space-y-4 text-base md:text-xl w-full font-Medium text-white py-4">
                  <li className="flex items-start">
                    <span className="li-square flex-shrink-0 mt-1.5 mr-3 md:mr-4"></span>
                    <span>Real-Time Dynamic Route Optimization</span>
                  </li>
                  <li className="flex items-start">
                    <span className="li-square flex-shrink-0 mt-1.5 mr-3 md:mr-4"></span>
                    <span>Proprietary AI-Powered Routing Algorithms</span>
                  </li>
                  <li className="flex items-start">
                    <span className="li-square flex-shrink-0 mt-1.5 mr-3 md:mr-4"></span>
                    <span>Prioritize EV & Green Fleet</span>
                  </li>
                  <li className="flex items-start">
                    <span className="li-square flex-shrink-0 mt-1.5 mr-3 md:mr-4"></span>
                    <span>End-To-End Delivery Visibility</span>
                  </li>
                </ul>

                {/* Social proof */}
                <div className="mt-4 md:mt-10">
                  <img
                    className="w-[75%] md:w-[60%] h-1/2 md:mt-2 mt-6"
                    src={"https://fareye.com/uploads/generic/images/Insights-white.png"}
                    alt="social proof"
                  />
                </div>
              </div>

              {/* Right side form */}
              <div id="hubSpotForm" className="w-full md:w-1/2 md:mt-10">
                <div className="bg-opacity-30 rounded-lg p-6 sm:p-8 md:p-10 md:ml-16">
                  <HubSpotFormComponent
                    hubspotFormId={hubspotFormId}
                    hubspotPortalId={hubspotPortalId}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </HeaderTempletWrapper>
  );
};
export default SharedSolutionHeaderSix;
