import React, { useState } from "react";
import { SanitizedHtmlDiv } from "../../../../components";
import { FeatureSectionWrapper } from "../styled";
import { FaArrowRightLong } from "react-icons/fa6";
import { ISolutionAsset } from "../../../../interfaces/solution";

interface FeatureComponentProps {
  assets: ISolutionAsset[];
  name: string;
}
export const SharedSolutionFeature: React.FC<FeatureComponentProps> = ({
  assets,
  name,
}) => {
  const sectionTitle = name;

  const scrollToForm = () => {
    document
      .getElementById("hubSpotForm")
      ?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <FeatureSectionWrapper>
      <section className="px-5 md:px-5 w-full py-12 md:pt-18 pb-0 max-w-7xl mx-auto">
        <div className="text-center mb-10 sm:mb-12 md:mb-16">
          <h2 className="text-2xl md:text-4xl font-Medium lg:text-5xl font-bold text-[#012A36] mb-3 sm:mb-4">
            {sectionTitle}
          </h2>
          <div className="w-16 sm:w-20 md:w-24 h-1 bg-blue-600 mx-auto"></div>
        </div>

        {assets.map((item, index) => (
          <div
            key={index}
            className={`mb-12 md:mb-16 flex flex-col ${
              index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
            } w-full gap-2 md:gap-12 rounded-2xl overflow-hidden border-black`}
          >
            <div className="w-full lg:w-1/2 overflow-hidden md:p-12 ">
              <div className="relative w-full h-64 md:h-full">
                {item.images.length !== 0 ? (
                  <img
                    className="w-full h-full object-cover object-center rounded-t-2xl md:rounded-none"
                    src={item?.images[0]?.url}
                    alt={item?.images[0]?.filename}
                  />
                ) : (
                  <div className="w-full h-full bg-slate-300 rounded-t-2xl md:rounded-none"></div>
                )}
                {/* <img
                  className="w-full h-full object-cover object-center rounded-t-2xl md:rounded-none"
                  src={item.imageUrl || fallBackImage}
                  alt={item.title}
                /> */}
              </div>
            </div>
            <div className="w-full lg:w-1/2 flex flex-col justify-start p-5 sm:p-8 md:p-8">
              <h3 className="md:text-3xl text-xl font-bold mb-4 text-[#012A36]">
                {item.title}
              </h3>
              <div className=" text-base md:text-lg mb-3">
                <SanitizedHtmlDiv
                  value={item.description_html}
                ></SanitizedHtmlDiv>
              </div>
              <button
                className={`font-semibold text-center border text-base md:text-lg py-2 px-4 md:py-2 lg:px-6 lg:py-2 rounded-full shadow-md w-fit flex items-center gap-3 group 
      ${
        index % 2 === 0
          ? "bg-[#FF7A64] text-[#012A36] hover:bg-[#ff7b64b2] border-none"
          : "bg-transparent text-[#012A36] hover:text-[#FF7A64] border-[#012A36]"
      }`}
                onClick={scrollToForm}
              >
                {/* {contentDetail.extraData.buttonText}{" "} */}
                Book A Meeting
                <FaArrowRightLong size={18} />
              </button>
            </div>
          </div>
        ))}
      </section>
    </FeatureSectionWrapper>
  );
};
