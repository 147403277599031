import * as stringUtils from "./string";
import * as fileUtils from "./file";
import moment from "moment";

export const copyToClipboard = (value: string) => {
  value && navigator.clipboard.writeText(value);
};

export function* cycler(itemsArray: Array<any>) {
  if (!itemsArray || itemsArray.length === 0) {
    throw new Error("No items available");
  }
  let index = 0;
  while (true) {
    yield itemsArray[index];
    index = (index + 1) % itemsArray.length;
  }
}

export const hexToRgba = (hex: string, alpha = 1) => {
  const cleanHex = hex.replace("#", "");
  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const formatCurrency = (
  value: number,
  options: Intl.NumberFormatOptions & {
    locale?: string;
    currency?: string;
  } = {}
) => {
  return new Intl.NumberFormat(options.locale || "en-US", {
    style: "currency",
    currency: options.currency || "USD",
    ...options,
  }).format(value);
};

export const formatNumber = (value: number, locale = "en-US", options = {}) => {
  return new Intl.NumberFormat(locale, options).format(value);
};

export const formatDate = (date: string, format = "MMM DD, YYYY") => {
  return moment(date).format(format);
};

export const formatTime = (date: string, format = "HH:mm") => {
  return moment(date).format(format);
};

export const formatDateAndTime = (
  date: string,
  format = "MMM DD, YYYY HH:mm"
) => {
  return `${formatDate(date, format)} ${formatTime(date, format)}`;
};

export const formatDateAndTimeWithSeconds = (
  date: string,
  format = "MMM DD, YYYY HH:mm:ss"
) => {
  return `${formatDate(date, format)} ${formatTime(date, format)}`;
};

export { stringUtils, fileUtils };
