import React from "react";
import Marquee from "react-fast-marquee";
import { ISolutionAsset } from "../../../../interfaces/solution";

interface CarouselComponentProps {
  assets: ISolutionAsset[];
  name: string;
}

const CarouselComponent: React.FC<CarouselComponentProps> = ({
  assets,
  name,
}) => {
  const sectionTitle = name;
  return (
    <section className="w-full flex flex-col items-center pb-0 py-14 sm:py-10  gap-5 sm:gap-5  ">
      {/* Header Text */}
      <p className="px-5 text-2xl text-center md:text-4xl font-Medium lg:text-4xl font-bold text-[#012A36] mb-3 sm:mb-4 md:py-10">
        {sectionTitle}
      </p>

      <div className="w-full overflow-hidden ">
        <Marquee
          gradient={false}
          speed={70}
          pauseOnHover={false}
          direction="left"
          loop={0}
          className="py-8 md:py-10 px-4 sm:px-8 md:px-16 lg:px-24"
        >
          {assets.map((item, index) =>
            // item?.imageUrl ? (
            //   <img
            //     key={index}
            //     src={item.imageUrl}
            //     alt={item.alt || item.title}
            //     className="h-auto max-w-[80px] sm:max-w-[100px] md:max-w-[120px] w-auto mx-4 sm:mx-8 md:mx-16 lg:mx-24 object-contain"
            //   />
            // ) : (
            //   <div className="h-auto max-w-[80px] sm:max-w-[100px] md:max-w-[120px] w-auto mx-4 sm:mx-8 md:mx-16 lg:mx-24 flex items-center justify-center text-gray-700 font-semibold rounded-md">
            //     {item.title}
            //   </div>
            // )

            // <img
            //   key={index}
            //   src={item?.images[0].url || fallBackImage}
            //   alt={item.images[0].filename || item.title}
            //   className="h-auto max-w-[80px] sm:max-w-[100px] md:max-w-[120px] w-auto mx-4 sm:mx-8 md:mx-16 lg:mx-24 object-contain"
            // />

            item.images.length !== 0 ? (
              <img
                key={index}
                src={item.images[0]?.url}
                alt={item.title || item.images[0]?.filename}
                className="h-auto max-w-[80px] sm:max-w-[100px] md:max-w-[120px] w-auto mx-4 sm:mx-8 md:mx-16 lg:mx-24 object-contain"
              />
            ) : null
          )}
        </Marquee>
      </div>
    </section>
  );
};

export default CarouselComponent;
