import React from "react";

export type THTMLHeadTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
export interface IOption {
  label: string;
  value: any;
}

// Used for tabs
export interface IComponentToRender {
  title: string;
  titleIcon?: React.ReactNode;
  object?: string;
  type?: string;
  slug: string;
  hidden?: boolean;
  componentToRender: () => React.ReactNode;
  addEditForm?: () => React.ReactNode;
}

export interface ITabMapper {
  [x: string]: number;
}

// used for accordion
// todo: to be changed as per the changes in the API

export interface IAccordionTag {
  id?: number;
  value: string;
}
export interface IAccordionItem {
  id?: number;
  title: string;
  description: string;
  tags?: IAccordionTag[];
  documents?: IDocumentObject[];
}

export interface IAccordionData {
  title: string;
  items: IAccordionItem[];
  children?: JSX.Element | JSX.Element[];
}

export interface IVerticalCardsItems {
  id: number;
  title: string;
  description: string;
}

export interface IVerticalCardsData {
  title: string;
  items: IVerticalCardsItems[];
  icon?: React.ReactNode;
  iconText?: string;
}

export interface ICardData {
  id: number | string;
  title: string;
  icon?: string | React.ReactNode | null;
  description?: string;
}

export interface IDocumentObject {
  id: number;
  filename?: string;
  title?: string;
  description: string;
  url: string;
  thumbnail_url?: string;
  created_from?: "gdrive" | "internal";
  src_url?: string;
}

export interface IMutationState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export interface IListAPIResponse<Data> {
  count: number;
  next: string;
  previous: string;
  data: Data;
}

export enum SocialAccountPlatformName {
  LINKEDIN = "linkedin",
  TWITTER = "twitter",
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  EMAIL = "email",
}
