import { useLoggedInTeam } from "../../../../api/hooks/useLoggedInTeam";
import { formatCompanyName } from "../../../../utils/string";

export const SharedSolutionFooter = () => {
  const loggedInTeamDetails = useLoggedInTeam();
  return (
    <footer className="bg-[#012A36] text-gray-400 py-4 px-6 md:py-6 md:px-10 bottom-0 w-full left-0">
      <div className="flex flex-col md:flex-row items-center justify-between text-sm">
        <p className="mb-2 md:mb-0">
          <span className="text-white font-normal">
            Copyright 2025 by{" "}
            <span>
              {loggedInTeamDetails?.name && (
                <span>{formatCompanyName(loggedInTeamDetails?.name)}</span>
              )}
              .
            </span>
          </span>
        </p>
        <p>
          Powered by <span className="text-white font-normal">Vink.ai</span>
        </p>
      </div>
    </footer>
  );
};
