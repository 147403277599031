import { useMutation } from "react-query";
import { toast } from "react-toastify";
import axiosInstance from "../axios";

const createShareableResource = async (postData: any) => {
  const data = {
    solutions: [postData.solutionId],
  };
  return await axiosInstance.post("shareables/", data);
};

const updateShareableResource = async (updatedData: any) => {
  const shareableResourceId = updatedData.id;
  delete updatedData.id;
  return await axiosInstance.patch(
    `shareables/${shareableResourceId}/`,
    updatedData
  );
};

const useCreateUpdateShareables = () => {
  const mutationFunction = (mutationData: any) => {
    return mutationData.id
      ? updateShareableResource(mutationData)
      : createShareableResource(mutationData);
  };
  const mutationOption = {
    onError: () => {
      toast.error("Oops, something went wrong. Please try again.");
    },
  };
  return useMutation(mutationFunction, mutationOption);
};

export default useCreateUpdateShareables;
