import styled from "@emotion/styled";
import { FormFieldWrapper } from "../styled";

export const TextFieldWrapper = styled(FormFieldWrapper)`
  min-height: 8rem;
  line-height: 150%;
  input {
    border: 0;
    font-size: 1.4rem;
    min-height: 4rem;
    :focus {
      outline: none;
    }
  }
`;

export const TextAreaContentWrapper = styled.div<{editorHeight?: string, editorUtilityWidth?: string}>`
  /* color: #71717a; */
  font-size: 1.3rem;
  line-height: 150%;
  font-weight: 400;
  cursor: text;
  .editor {
    min-height: ${(props) => props.editorHeight || "42rem"};
    
  }
  .field-placeholder {
    color: #a1a1aa99;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2rem;
    cursor: text;
    min-height: 4rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }
  .ql-indent-1 {
    padding-left: 4rem;
  }
`;

export const TextAreaContainer = styled.div`
  textarea {
    width: 100%;
    max-width: 100%;
    color: #71717a;
    border: none;
    font-family: var(--font-primary);
    font-weight: 400;
    font-size: 1.3rem;
    resize: none;
    :focus {
      outline: none;
      min-height: 12rem;
    }
    ::placeholder {
      color: #a1a1aa;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 2rem;
    }
  }
`;
