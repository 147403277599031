import { useEffect } from "react";
import { HubSpotFormContainer } from "./styled";

interface FormProps {
  hubspotPortalId: string; 
  hubspotFormId: string; 
}

const HubSpotFormComponent: React.FC<FormProps> = ({
  hubspotPortalId,
  hubspotFormId,
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    script.async = true;
    script.onload = () => {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          portalId: hubspotPortalId,
          formId: hubspotFormId,
          target: "#hubspotFormContainer",
        });
      }
    };
    document.body.appendChild(script);
  }, []);

  return (
      <HubSpotFormContainer>
        <div className="max-w-md mx-auto">
          <div
            id="hubspotForm"
            className="overflow-auto px-6 py-8 bg-white shadow-lg text-black rounded-lg relative"
          >
            {/* Heading inside the white form box */}
            <h3 className="text-black text-center mb-6 text-2xl md:text-3xl font-semibold">
              Book A Meeting
            </h3>
            <div id="hubspotFormContainer"></div>
          </div>
        </div>
      </HubSpotFormContainer>
  );
};

export default HubSpotFormComponent;
