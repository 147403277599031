import { useState } from "react";
import { Global, css } from "@emotion/react";
import { Helmet } from "react-helmet";
import SharedSolutionHeaderSix from "./SharedSolutionHeaderSix";
import { SharedSolutionFooter } from "./components/SharedSolutionFooter";

import { SharedSolutionDetailsContentSix } from "./SharedSolutionDetailsContentSix";
export const SharedSolutionDetailsSix = () => {
  const [styleData, setStyleData] = useState({
    template: "template_3",
    themes: [
      {
        imports: {
          fonts: [
            "https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap",
          ],
        },
        is_default: true,
        key: "fareye_theme1",
        name: "FarEye Theme 1",
        style: {
          body: {
            content: {
              footer: {},
              header: {
                body: {
                  background:
                    "url('https://storage.googleapis.com/mock_images_for_gp/fareye_background_1.png')",
                },
                navLogo: {
                  borderRadius: "10px",
                  height: "70px",
                },
                title: {
                  fontSize: "2rem",
                  fontWeight: "500",
                  lineHeight: "150%",
                },
              },
              section: {
                body: {},
                navTitle: {},
                title: {
                  color: "white",
                  fontSize: "3rem",
                  fontWeight: "500",
                  padding: "1.5% 2%",
                },
              },
              section1: {
                body: {
                  fontSize: "1.8rem",
                  fontWeight: 300,
                },
                navTitle: {},
                title: {
                  color: "black",
                  fontSize: "2.3rem",
                },
              },
              subSection: {
                body: {
                  content: {
                    h2: {},
                    h3: {},
                    normal: {
                      fontSize: "1.8rem",
                      fontWeight: 300,
                    },
                    title: {},
                  },
                  style: {},
                },
                navTitle: {},
                title: {
                  color: "black",
                  fontSize: "2.3rem",
                },
              },
              heroSection: {
                fontSize: "3rem",
                bgColor: "Blue",
                bgImage:
                  "https://www.feyenschliff.de/wp-content/uploads/2021/05/1213-580%C3%97310.jpg",
              },
            },
            style: {
              fontFamily: "Outfit, sans-serif",
              fontSize: "2.3rem",
            },
          },
        },
      },
    ],
  });

  const fontFamily = styleData.themes[0].style.body.style.fontFamily;
  return (
    <div>
      <Helmet>
        <link href={styleData.themes[0].imports.fonts[0]} rel="stylesheet" />
      </Helmet>
      <Global
        styles={css`
          html,
          body {
            font-size: 16px;
            font-family: ${fontFamily};
          }
        `}
      />
      <SharedSolutionHeaderSix />
      <SharedSolutionDetailsContentSix/>
      <SharedSolutionFooter />
    </div>
  );
};
