import React, { useContext, useRef, useState } from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useLoggedInTeam } from "../../../api/hooks/useLoggedInTeam";
import { useSharingLink } from "../../../api/hooks/useSharingLink";
import { AuthContext, SolutionsContext } from "../../../context";
import { useOnClickOutside } from "../../../hooks";
import {
  SharedSolutionHeaderContainer,
  SharedSolutionNavBar,
  SharedSolutionHeaderBody,
  SolutionTitle,
  SolutionLogoContainer,
  ButtonContainer,
  ShareButton,
  ShareButtonWrapper,
  SolutionSharingContainer,
  SolutionSharingBody,
  CopyButton,
  LinkcopiedMessage,
  SharingLinkContainer,
  EditButton,
} from "./styled";
import { TbPencil } from "react-icons/tb";
import { appConfig } from "../../../config";
import { useTheme } from "@emotion/react";
import { ITheme } from "../../../interfaces/theme";

const appBaseUrl = process.env.REACT_APP_BASE_URL;
interface ISolutionSharingForClient {
  isOpen?: boolean;
}
const SolutionSharingForClient = ({ isOpen }: ISolutionSharingForClient) => {
  const theme: ITheme = useTheme();
  const [showLinkCopiedMessage, setShowCopiedMessage] = useState(false);
  const guestCode = sessionStorage.getItem("guestCode");
  const sharingLink = useSharingLink(guestCode || "");
  
  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(sharingLink);
    setShowCopiedMessage(true);
    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 1500);
  };
  
  const headerStyle = theme?.sharedView?.style?.body?.content?.header

  return (
    <SolutionSharingContainer isOpen={isOpen} className="client-view">
      <SolutionSharingBody style={{ paddingBottom: "0" }}>
        <SharingLinkContainer className="client-view">
          <p>{sharingLink}</p>
          <CopyButton onClick={() => handleCopyLinkClick()} style={headerStyle?.buttonStyle}>
            <span>Copy link</span>
          </CopyButton>
        </SharingLinkContainer>
        <>
          <div style={{ height: "2rem" }}>
            {showLinkCopiedMessage && (
              <LinkcopiedMessage>
                <IoCheckmarkCircle fill="#16A34A" size={14}></IoCheckmarkCircle>
                <span>Link copied</span>
              </LinkcopiedMessage>
            )}
          </div>
        </>
      </SolutionSharingBody>
    </SolutionSharingContainer>
  );
};

const SharedSolutionHeaderOne = () => {
  const theme: ITheme = useTheme();
  const loggedInTeamDetails = useLoggedInTeam();
  const [isOpen, setIsOpen] = useState(false);
  const {
    solutionsState: { solutionObject },
  } = useContext(SolutionsContext);
  const {
    authState: { isPreview },
  } = useContext(AuthContext);
  const SolutionTitleValue = solutionObject?.title;
  const OpportunityLogo = solutionObject?.logo?.replace("api/web/", "");
  const backgroundColor = solutionObject?.proposal_color as string;
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const handleEditButtonClick = () => {
    window.location.replace(
      appBaseUrl +
        appConfig.pageSpecificConfigs.solution.parentUrl +
        `/${solutionObject?.id}?tab=solution`
    );
  };

  const headerStyle = theme?.sharedView?.style?.body?.content?.header

  return (
    <SharedSolutionHeaderContainer background={headerStyle?.body?.background}>
      <SharedSolutionNavBar id="shared-solution-top-nav" className="container">
        <div>
          {loggedInTeamDetails?.logo && (
            <img
              src={loggedInTeamDetails?.logo.replace("api/web/", "")}
              alt="team logo"
              style={headerStyle?.navLogo}
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.parentElement.style.display = 'none';
              }}
            /> 
          )}
        </div>
        <ButtonContainer>
          {isPreview && (
            <EditButton onClick={handleEditButtonClick} style={headerStyle?.buttonStyle}>
              <TbPencil size={16} />
              <span>Edit</span>
            </EditButton>
          )}
          <ShareButtonWrapper ref={ref}>
            <ShareButton onClick={() => setIsOpen((prevState) => !prevState)} style={headerStyle?.buttonStyle}>
              <span>Share</span>
            </ShareButton>
            <SolutionSharingForClient isOpen={isOpen} />
          </ShareButtonWrapper>
        </ButtonContainer>
      </SharedSolutionNavBar>
      {/* <div style={{ border:"1px solid red",borderBottomLeftRadius: "10rem", "overflow": "hidden" }}> */}
      <SharedSolutionHeaderBody
      // style={headerStyle?.body}
        className="container"
      >
        <div
          style={{ borderLeft: "1px solid #ffffff52", "flexBasis" : "40%", marginLeft: "3rem" }}
        >
          <SolutionTitle
            style={headerStyle?.title}
          >
            {SolutionTitleValue}
          </SolutionTitle>
        </div>
        {OpportunityLogo && (
          <SolutionLogoContainer>
            <img 
              src={OpportunityLogo} 
              alt=""               
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.parentElement.style.display = 'none';
              }}
            />
          </SolutionLogoContainer>
        )}
      </SharedSolutionHeaderBody>
      {/* </div> */}
    </SharedSolutionHeaderContainer>
  );
};

export default SharedSolutionHeaderOne;
