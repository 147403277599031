export const COLOR_PALLETTE = [
  "#DEF1E9", // Soft mint green (Light)
  "#FFEED2", // Light orange (Light)
  "#ffe1e1", // Soft coral pink (Light)
  "#C9DCFF", // Light blue (Light)
  "#E7EED2", // Soft lime (Light)
  "#DFD2EE", // Soft purple (Light)
  "#FFE1D2", // Peach (Light)
  "#EED2DF", // Soft rose (Light)
  "#D2F1EC", // Soft turquoise (Light)
  "#EEE1D2", // Light brown (Light)
  "#D2E1EE", // Powder blue (Light)
  "#D2FFE1", // Mint (Light)
];
export const COLOR_PALLETTE_1 = [
  "#96DDBF", // Soft mint green
  "#FFD38A", // Light orange
  "#ffa0a0", // Soft coral pink
  "#8FB7FF", // Light blue
  "#B996DD", // Soft purple
  "#DDB996", // Light brown
  "#96DDC7", // Soft turquoise
  "#DD96B9", // Soft rose
  "#C7DD96", // Soft lime
  "#96B9DD", // Powder blue
  "#FFB78A", // Peach
  "#8AFFB7", // Mint
];

export const ACCOUNT_REFETCH_INTERVAL_IN_MS = 10000;
export const ACCOUNT_LIST_PAGE_SIZE = 11;
export const ACCOUNT_LIST_REFETCH_INTERVAL_IN_MS = 6000;
export const ACCOUNT_RANDOM_ICONS = [
  {
    iconName: "FaAlignLeft",
    iconPath: "react-icons/fa",
    iconStyleClasses: "bg-purple-100 text-purple-600",
  },
  {
    iconName: "FaAsterisk",
    iconPath: "react-icons/fa",
    iconStyleClasses: "bg-purple-100 text-purple-600",
  },
  {
    iconName: "FaBandcamp",
    iconPath: "react-icons/fa",
    iconStyleClasses: "bg-purple-100 text-purple-600",
  },
  {
    iconName: "FaBuffer",
    iconPath: "react-icons/fa",
    iconStyleClasses: "bg-purple-100 text-purple-600",
  },
  {
    iconName: "FaDotCircle",
    iconPath: "react-icons/fa",
    iconStyleClasses: "bg-purple-100 text-purple-600",
  },
  {
    iconName: "FaForward",
    iconPath: "react-icons/fa",
    iconStyleClasses: "bg-purple-100 text-purple-600",
  },
  {
    iconName: "FaHashtag",
    iconPath: "react-icons/fa",
    iconStyleClasses: "bg-purple-100 text-purple-600",
  },
  {
    iconName: "FaInstalod",
    iconPath: "react-icons/fa",
    iconStyleClasses: "bg-purple-100 text-purple-600",
  },
  {
    iconName: "FaPaperPlane",
    iconPath: "react-icons/fa",
    iconStyleClasses: "bg-purple-100 text-purple-600",
  },
];
