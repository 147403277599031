import React from "react";
import { CardWrapper } from "../styled";
import { ISolutionAsset } from "../../../../interfaces/solution";

interface CardComponentProps {
  assets: ISolutionAsset[];
  name: string;
}

export const CardComponent: React.FC<CardComponentProps> = ({
  assets,
  name,
}) => {
  
  return (
    <CardWrapper>
      {/* Card Section */}
      <section className="px-5 w-full md:px-36 pb-10 ">
        <h2 className="text-2xl md:text-4xl font-Medium lg:text-4xl font-bold text-[#012A36] mb-3 sm:mb-4 md:py-10 pb-8">
          {name}
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-12">
          {assets.map((card) => (
            <div
              key={card.id}
              className="cursor-pointer bg-white rounded-lg overflow-hidden myCard group"
              onClick={() =>
                window.open(card?.extra_data?.url ,"_blank","noopener,noreferrer")
              }
            >
              {card?.images.length !== 0 ? (
                <img
                  src={card.images[0]?.url}
                  alt={card.title}
                  className="w-full h-40 md:h-48 object-cover object-left"
                />
              ) : (
                <div className="w-full h-40 md:h-48 object-cover object-left bg-slate-300"></div>
              )}

              <div className="p-3 md:p-4">
                <p className="text-sm md:text-lg font-medium mb-2 md:mb-3 line-clamp-2">
                  {card.description}
                </p>
                <div className="mt-2 hover:text-orange-600 flex font-bold text-lg">
                  <span className="text-[rgb(1 42 54)] py-1.5 rounded-md text-sm group-hover:text-[#FF7964]">
                    {card?.extra_data?.button_text || "Learn More"}
                  </span>
                  <div>
                    <svg
                      className="w-[23px] ml-[14px] group-hover:text-[#FF7964]"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 23 12"
                    >
                      <path
                        d="M21.8 5.1 17.5.8c-.5-.5-1.3-.5-1.8 0-.2.2-.4.6-.4.9 0 .3.1.7.4.9l2 2H1.8C1.1 4.7.5 5.3.5 6s.6 1.3 1.3 1.3h15.9l-2 2c-.5.5-.5 1.3 0 1.8.3.3.6.4.9.4.3 0 .7-.1.9-.4l4.2-4.2c.3-.2.4-.6.4-.9 0-.3-.1-.6-.3-.9z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </CardWrapper>
  );
};
