import React from "react";
import { ISolutionAsset } from "../../../../interfaces/solution";

interface ImageProps {
  assets: ISolutionAsset[];
  name: string;
}

export const ImageComponent: React.FC<ImageProps> = ({ assets, name }) => {
  return (
    <section className="px-5 md:px-0 w-full flex flex-col items-center py-10 md:py-14">
      <div className="flex items-center justify-center flex-col w-full md:w-3/4">
        <h2 className="text-2xl md:text-4xl font-extrabold font-Medium text-center mb-10 md:mb-12 text-[#012A36]">
          {name}
        </h2>
        {assets.map((item) => {
          return item.images.length > 0 ? (
            <img
              src={item?.images[0]?.url}
              alt={item.title}
              className="object-cover w-full"
            />
          ) : null;
        })}
      </div>
    </section>
  );
};
