import { useParams } from "react-router-dom";
import useGetSharedResourcePublicData from "../../api/hooks/useGetShareableResPublicData";
import SharedSolutionDetails from "./SharedResourceTemplate/SharedSolutionDetails";
import SharedSolutionDetailsOne from "./SharedResourceTemplateOne/SharedSolutionDetailsOne";
import SharedSolutionDetailsThree from "./SharedResourceTemplateThree/SharedSolutionDetailsThree";
import SharedSolutionDetailsFour from "./SharedResourceTemplateFour/SharedSolutionDetailsFour";
import { SharedSolutionDetailsSix } from "./SharedResourceTemplateSix/SharedSolutionDetailsSix";


const templateMapping = {
  template_1: <SharedSolutionDetails />,
  template_2: <SharedSolutionDetailsOne />,
  template_3: <SharedSolutionDetailsThree />,
  template_4: <SharedSolutionDetailsFour />,
  template_5: <SharedSolutionDetailsSix/>
};

const SharedResourcePage = () => {
  const params = useParams();
  const code = params.code || sessionStorage.getItem("guestCode");
  const { data: sharedResPublicData } = useGetSharedResourcePublicData(code!);

  const templateKey = (sharedResPublicData?.assets?.template ||
    Object.keys(templateMapping)[1]) as keyof typeof templateMapping;
  return (
    templateMapping[templateKey] ||
    templateMapping[
      Object.keys(templateMapping)[1] as keyof typeof templateMapping
    ]
  );
};

export default SharedResourcePage;
