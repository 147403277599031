// import * as React from "react"
// import * as SliderPrimitive from "@radix-ui/react-slider"

// import { cn } from "./lib/utils"

// const Slider = React.forwardRef<
//   React.ElementRef<typeof SliderPrimitive.Root>,
//   React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
// >(({ className, ...props }, ref) => (
//   <SliderPrimitive.Root
//     ref={ref}
//     className={cn(
//       "relative flex w-full touch-none select-none items-center",
//       className
//     )}
//     {...props}
//   >
//     <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-slate-300">
//       <SliderPrimitive.Range className="absolute h-full bg-blue-500" />
//     </SliderPrimitive.Track>
//     <SliderPrimitive.Thumb className="block h-5 w-5 rounded-full border-2 border-primary bg-blue-300 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
//   </SliderPrimitive.Root>
// ))
// Slider.displayName = SliderPrimitive.Root.displayName

// export { Slider }




import * as React from "react"
import * as SliderPrimitive from "@radix-ui/react-slider"

import { cn } from "./lib/utils"

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, value = [0, 100], ...props }, ref) => {
  // Ensure value is treated as an array
  const sliderValue = Array.isArray(value) ? value : [value]

  return (
    <SliderPrimitive.Root
      ref={ref}
      className={cn("relative flex w-full touch-none select-none items-center", className)}
      value={sliderValue}
      {...props}
    >
      {/* Track (Unfilled Area: Slate-300) */}
      <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-slate-300">
        {/* Range (Filled Area: Blue-500) */}
        <SliderPrimitive.Range className="absolute h-full bg-blue-500" />
      </SliderPrimitive.Track>

      {/* Render One or Two Thumbs Based on Whether It's a Single or Double Slider */}
      {sliderValue.map((_, index) => (
        <SliderPrimitive.Thumb
          key={index}
          className={cn(
            "block h-6 w-6 rounded-full border-2 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
            index === 0 ? "bg-blue-300 border-blue-600" : "bg-blue-300 border-blue-300"
          )}
        />
      ))}
    </SliderPrimitive.Root>
  )
})

Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
















// import * as React from "react";
// import * as SliderPrimitive from "@radix-ui/react-slider";
// import { cn } from "./lib/utils";

// interface SliderProps extends React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> {
//   value: [number, number]; 
//   onValueChange: (value: [number, number]) => void; 
//   min?: number; 
//   max?: number; 
//   step?: number;
// }

// const Slider = React.forwardRef<
//   React.ElementRef<typeof SliderPrimitive.Root>,
//   SliderProps
// >(({ className, value, onValueChange, min = 0, max = 100, step = 1, ...props }, ref) => (
//   <SliderPrimitive.Root
//     ref={ref}
//     className={cn("relative flex w-full touch-none select-none items-center", className)}
//     value={value}
//     onValueChange={(newValue) => onValueChange([newValue[0], newValue[1]])}
//     min={min}
//     max={max}
//     step={step}
//     {...props}
//   >
//     {/* Track (Unfilled Area: Slate-400) */}
//     <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-slate-400">
//       {/* Range (Filled Area: Blue-500) */}
//       <SliderPrimitive.Range className="absolute h-full bg-blue-500 rounded-full" />
//     </SliderPrimitive.Track>

//     {/* Thumb for Min Value (Now Blue-300, No Border) */}
//     <SliderPrimitive.Thumb className="block h-6 w-6 rounded-full bg-blue-300 shadow-lg transition-transform transform hover:scale-110" />

//     {/* Thumb for Max Value (Now Blue-300, No Border) */}
//     <SliderPrimitive.Thumb className="block h-6 w-6 rounded-full bg-blue-300 shadow-lg transition-transform transform hover:scale-110" />
//   </SliderPrimitive.Root>
// ));

// Slider.displayName = SliderPrimitive.Root.displayName;

// export { Slider };
