import jwtDecode from "jwt-decode";
import noop from "lodash/noop";
import { Dispatch } from "react";
import { appConfig } from "../../config";
import {
  ILoggedInUserResponse,
  ISetIsOwnerAndSignupFunc,
  IToken,
  TAuthReducerAction,
} from "../../interfaces";
import { history } from "../../utils/history";

const {
  urls: { workspaceSelection: workspaceSelectionUrl },
} = appConfig;

export function getLoggedInUser() {
  return localStorage.getItem("loggedInUser")
    ? (JSON.parse(
        localStorage.getItem("loggedInUser") || ""
      ) as unknown as ILoggedInUserResponse)
    : null;
}

export function storeUserInfoAndAuthenticationDetails(
  authDispatch: Dispatch<TAuthReducerAction>,
  data: any,
  cb = noop
) {
  // Todo: can be refactored userToken object can be used to access requisite userInfo no need for decode
  localStorage.setItem("access", data.access);
  localStorage.setItem("refresh", data.refresh);
  localStorage.setItem("teamId", data.team.id);
  const userInfo: IToken = jwtDecode(data.access);
  const { user_id, email, display_name } = userInfo;

  authDispatch({
    type: "SET_USER",
    payload: {
      userInfo: {
        userId: user_id.toString(),
        email: email,
        display_name: display_name,
      },
    },
  });
  cb();
  // history.push(workspaceSelectionUrl);
}

export function clearUpAndGoToHome() {
  localStorage.clear();
  sessionStorage.clear();
  history.push("/");
  window.location.replace("/");
  window.location.reload();
}

export function setIsOwnerAndSignup({
  loggedInUser,
  is_signup,
  is_owner,
}: ISetIsOwnerAndSignupFunc) {
  loggedInUser.is_signup = is_signup;
  loggedInUser.user.is_owner = is_owner;
  localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));
}
