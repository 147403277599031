export function watchBreakpointChange(callback: (breakpoint: string) => void) {
  function checkBreakpoint() {
    const width = window.innerWidth;
    let breakpoint: string = "md";

    if (width > 1100) breakpoint = "md";
    else if (width <= 640) breakpoint = "sm";

    callback(breakpoint);
  }

  window.addEventListener("resize", checkBreakpoint);
  checkBreakpoint();

  return () => window.removeEventListener("resize", checkBreakpoint);
}
