import DOMPurify from "dompurify";
import moment from "moment";
import { formatNumber } from "./index";
import { capitalize } from "lodash";
export function singular(word: string, amount?: number): string {
  if (amount !== undefined && amount !== 1) {
    return word;
  }
  const singular: { [key: string]: string } = {
    "(quiz)zes$": "$1",
    "(matr)ices$": "$1ix",
    "(vert|ind)ices$": "$1ex",
    "^(ox)en$": "$1",
    "(alias)es$": "$1",
    "(octop|vir)i$": "$1us",
    "(cris|ax|test)es$": "$1is",
    "(shoe)s$": "$1",
    "(o)es$": "$1",
    "(bus)es$": "$1",
    "([m|l])ice$": "$1ouse",
    "(x|ch|ss|sh)es$": "$1",
    "(m)ovies$": "$1ovie",
    "(s)eries$": "$1eries",
    "([^aeiouy]|qu)ies$": "$1y",
    "([lr])ves$": "$1f",
    "(tive)s$": "$1",
    "(hive)s$": "$1",
    "(li|wi|kni)ves$": "$1fe",
    "(shea|loa|lea|thie)ves$": "$1f",
    "(^analy)ses$": "$1sis",
    "((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)ses$": "$1$2sis",
    "([ti])a$": "$1um",
    "(n)ews$": "$1ews",
    "(h|bl)ouses$": "$1ouse",
    "(corpse)s$": "$1",
    "(us)es$": "$1",
    s$: "",
  };
  const irregular: { [key: string]: string } = {
    move: "moves",
    foot: "feet",
    goose: "geese",
    sex: "sexes",
    child: "children",
    man: "men",
    tooth: "teeth",
    person: "people",
  };
  const uncountable: string[] = [
    "sheep",
    "fish",
    "deer",
    "moose",
    "series",
    "species",
    "money",
    "rice",
    "information",
    "equipment",
    "bison",
    "cod",
    "offspring",
    "pike",
    "salmon",
    "shrimp",
    "swine",
    "trout",
    "aircraft",
    "hovercraft",
    "spacecraft",
    "sugar",
    "tuna",
    "you",
    "wood",
  ];
  if (uncountable.indexOf(word.toLowerCase()) >= 0) {
    return word;
  }
  // check for irregular forms
  for (const w in irregular) {
    const pattern = new RegExp(`${irregular[w]}$`, "i");
    const replace = w;
    if (pattern.test(word)) {
      return word.replace(pattern, replace);
    }
  }

  // check for matches using regular expressions
  for (const reg in singular) {
    const pattern = new RegExp(reg, "i");
    if (pattern.test(word)) {
      return word.replace(pattern, singular[reg]);
    }
  }
  return word;
}

export const dateToTimeIntervalFromNow = (datetimeStr: string) => {
  if (!datetimeStr) return;
  const newDate: any = new Date(datetimeStr);
  return moment(newDate).fromNow();
};

export const getObjectValueFromDotString = (
  nestedObj: object,
  dotNotationStr: string
): any => {
  if (!dotNotationStr) return undefined;
  const propertyNameList = dotNotationStr.split(".");
  const getValue = (obj: object, propertyNameArr: Array<string>): any => {
    const value = obj[propertyNameArr[0] as keyof typeof obj];
    if (typeof value === "object" && !Array.isArray(value) && value !== null) {
      if (propertyNameArr.length > 1) {
        return getValue(value, propertyNameArr.slice(1));
      }
    }
    return propertyNameArr.length === 1 ? value : undefined;
  };
  return getValue(nestedObj, propertyNameList);
};

export const sanitizeHtml = (htmlText: string) => {
  return DOMPurify.sanitize(htmlText || "", {
    ADD_TAGS: ["iframe", "object"],
    ADD_ATTR: [
      "allow",
      "allowfullscreen",
      "frameborder",
      "scrolling",
      "style",
      "target",
      "data",
    ],
  });
};

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const highlightStrongText = (element?: HTMLElement) => {
  const eles = (element || document).querySelectorAll("strong");
  eles.forEach((ele) => {
    ele.classList.add("bg-[#dcff8c]", "p-[2px]");
  });
};

export const getCountRange = (count: number) => {
  const getRange = (min: number, max: number) => {
    return `${formatNumber(min)}-${formatNumber(max)}`;
  };
  if (count < 1) return "0";
  if (count < 10) return getRange(1, 10);
  if (count < 50) return getRange(11, 50);
  if (count < 100) return getRange(51, 100);
  if (count < 250) return getRange(101, 250);
  if (count < 500) return getRange(251, 500);
  if (count < 1000) return getRange(501, 1000);
  if (count < 5000) return getRange(1001, 5000);
  if (count < 10000) return getRange(5001, 10000);
  return "10,000+";
};

export const multiReplaceRegex = (
  string: string,
  replacements: Record<string, string>,
  ignoreCase = false
) => {
  for (const [pattern, repl] of Object.entries(replacements)) {
    try {
      const escapedPattern =
        "(?<!\\w)" + pattern.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") + "(?!\\w)";
      const flags = ignoreCase ? "gi" : "g";
      const regex = new RegExp(escapedPattern, flags);
      string = string.replace(regex, repl || "");
    } catch (error) {
      console.warn(
        `Error while replacing pattern: ${pattern}; replace_str: ${repl} -> ${error}`
      );
    }
  }
  return string;
};

export const encodeTextFragment = (text: string) => {
  /**
   * For every character in the text:
   * - If it's an ASCII letter (A-Z, a-z) or a digit (0-9), leave it as-is.
   * - Otherwise, convert it to its percent-encoded representation with two-digit uppercase hex.
   */
  return Array.from(text)
    .map((char) => {
      if (/^[A-Za-z0-9]$/.test(char)) {
        return char;
      }
      const encodeChar = (char: string) =>
        "%" + char.charCodeAt(0).toString(16).toUpperCase().padStart(2, "0");
      return encodeChar(char);
    })
    .join("");
};

export const countWords = (text: string) => {
  return text
    .trim()
    .split(/\s+/)
    .filter((word: string) => word.length > 0).length;
};

export const calculateReadingTime = (text: string, wordsPerMinute = 200) => {
  const wordCount = text
    .trim()
    .split(/\s+/)
    .filter((word) => word.length > 0).length;
  const readingTime = Math.ceil(wordCount / wordsPerMinute);
  return readingTime;
};

export const formatCompanyName = (name: string) => {
  return name
    .split(" ")
    .map((word) => {
      if (word === word.toUpperCase()) return word; // Keep acronyms
      return capitalize(word); // Title case other words
    })
    .join(" ")
    .replace(/\b(Mc|Mac|O')([a-z])/g, (match, prefix, letter) =>
      prefix + letter.toUpperCase()
    );
};