import React from "react";
import AnimatedNumberCounter from "../../../../components/compositional/Reusable/components/AnimatedCounter";
import { ISolutionAsset } from "../../../../interfaces/solution";


interface CounterComponentProps {
  assets: ISolutionAsset[];
  name: string;
}

export const CounterComponent: React.FC<CounterComponentProps> = ({
  assets,
  name,
}) => {
  const sectionTitle = name;
  const bgColor = ["#e6f7fa", "#ffded4", "#fff5e6", "#e6f7fa"];

  const extractTitleParts = (title: string) => {
    const match = title.match(/^(.*?)\s*(\d+)\s*(.*)$/) || [];
    return {
      prefix: match[1]?.trim() || "",
      number: match[2] || "",
      suffix: match[3]?.trim() || "",
    };
  };

  return (
    <section className="px-5 md:px-0 w-full flex flex-col items-center">
      <h2 className="text-2xl md:text-4xl font-extrabold font-Medium text-center mb-10 md:mb-12 text-gray-800 mt-12 md:mt-24">
        {sectionTitle}
      </h2>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 w-full max-w-6xl">
        {assets.map((item, index) => {
          const { prefix, number, suffix } = extractTitleParts(item.title);

          return (
            <div
              key={item.id}
              className="rounded-lg p-1 md:p-5 flex flex-col items-center justify-center text-center min-h-[180px] md:min-h-[220px]"
              style={{ backgroundColor: bgColor[index % bgColor.length] }}
            >
              <div className="mb-4 md:mb-6 text-gray-800 pt-2 md:pt-4">
                <AnimatedNumberCounter
                  to={parseInt(number)}
                  prefix={prefix || ""}
                  suffix={suffix || ""}
                  className="text-4xl md:text-5xl lg:text-6xl font-bold inline-block"
                />
              </div>

              <p className="text-sm md:text-base font-semibold text-gray-700 pb-2 md:pb-4">
                {item.description}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};
