import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  useField,
} from "formik";
import { useEffect, useRef, useState } from "react";
import RichTextEditor from "../../RichTextEditor/RichTextEditor";
import RichTextEditor1 from "../../RichTextEditor/RichTextEditor1";
import { FormErrorText } from "../common";
import {
  TextAreaContainer,
  TextAreaContentWrapper,
  TextFieldWrapper,
} from "./styled";

interface IFormTextAreaField {
  label: string;
  name: string;
  formikField?: FieldInputProps<any>;
  formikMeta?: FieldMetaProps<any>;
  formikHelpers?: FieldHelperProps<any>;
  [x: string]: any;
}

const FormTextAreaField = ({ label, ...allProps }: IFormTextAreaField) => {
  const [isFocused, setFocused] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const inputElementRef = useRef(null);
  const {
    formikField,
    formikMeta,
    formikHelpers,
    isRichText,
    required: isRequired,
    ...props
  } = allProps;

  useEffect(() => {
    let element = null;
    // if (isRichText) {
    //@ts-ignore
    // const editor = inputElementRef?.current?.editor;
    // element = editor?.scrollingContainer;
    // editor.root.setAttribute("spellcheck", false);
    // } else {
    //   element = inputElementRef?.current;
    // }
    element = inputElementRef?.current;
    if (element && !isRichText) {
      (element as HTMLElement)?.addEventListener("focusin", () =>
        setFocused(true)
      );
      (element as HTMLElement)?.addEventListener("focusout", () =>
        setFocused(false)
      );
    }
  }, []);

  const handleEditorValueChange = (value: any) => {
    // Handle value change when a blur event is triggered on editor
    // or the editor wrapper
    // const valueToUpdate = value?.replace(/^<p><br\/><\/p>/, "");
    const valueToUpdate = value;
    if (formikField?.value !== valueToUpdate) {
      formikHelpers?.setValue(valueToUpdate);
      formikHelpers?.setTouched(true);
    }
  };
  
  return (
    <TextFieldWrapper isFocused={isFocused} isRequired={label && isRequired}>
      <label id={props.id || props.name} htmlFor={props.id || props.name}>
        {label}
      </label>
      <TextAreaContentWrapper
        onClick={() => {
          setIsEdit(true);
        }}
        editorHeight={props.height}
      >
        {isRichText ? (
          <RichTextEditor1
            className="editor"
            hideToolBar={true}
            // hidden={!isEdit}
            editorRef={inputElementRef}
            value={formikField?.value}
            // OnChange is triggered when the wrapper
            // fires a blur event
            onChange={handleEditorValueChange}
            // onBlur={(value: string) => {
            //   setIsEdit(false);
            //   setFocused(false);
            //   handleEditorValueChange(value);
            // }}
            placeholder={props.placeholder}
            setFocused={setFocused}
          />
        ) : (
          <TextAreaContainer>
            <textarea
              spellCheck={false}
              rows={4}
              ref={inputElementRef}
              {...formikField}
              {...props}
              onBlur={(e) => {
                setIsEdit(false);
                formikField?.onBlur(e);
              }}
            ></textarea>
          </TextAreaContainer>
        )}
      </TextAreaContentWrapper>
      <FormErrorText formikMeta={formikMeta} />
    </TextFieldWrapper>
  );
};

const FormikTextAreaField = (props: IFormTextAreaField) => {
  const [field, meta, helpers] = useField(props);
  return (
    <FormTextAreaField
      formikField={field}
      formikMeta={meta}
      formikHelpers={helpers}
      {...props}
    ></FormTextAreaField>
  );
};

export { FormTextAreaField, FormikTextAreaField };
