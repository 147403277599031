import React, { useContext, useEffect, useState } from "react";
import { SharedSolutionFeature } from "./components/SharedSolurionFeature";
import { useParams } from "react-router-dom";
import useCreateUpdateShareables from "../../../api/hooks/useCreateUpdateShareables";
import { SolutionsContext } from "../../../context";
import {
  initialShareableResObj,
  SolutionsActionType,
} from "../../../interfaces";
import { history } from "../../../utils/history";
import { userActivityEvents } from "../../../config";
import { trackUserAction } from "../../../analytics";
import useGetSolutionAssets from "../../../api/hooks/useGetSolutionAssets";
import useGetSolutionAssetTypes from "../../../api/hooks/useGetSolutionAssetTypes";
import CarouselComponent from "./components/sharedSolutionCarousal";
import { CardComponent } from "./components/SharedSolutionCard";
import { CounterComponent } from "./components/SharedSolutionCounterComponent";
import { ImageComponent } from "./components/SharedSolutionImageComponent";
import { ISolutionAsset } from "../../../interfaces/solution";
import { MdViewKanban } from "react-icons/md";

export const SharedSolutionDetailsContentSix = () => {
  const [segregatedData, setSegregatedData] = useState<
    Record<string, { name: string; items: DataItem[] }>
  >({});
  const params = useParams();

  const { mutate } = useCreateUpdateShareables();
  const { solutionsState, solutionsDispatch, setShareableResObj } =
    useContext(SolutionsContext);

  //hook to get solution types
  const { data: solutionAssetTypesData } = useGetSolutionAssetTypes(
    solutionsState.solutionId || ""
  );
  // console.log(solutionAssetTypesData?.data, "i am the solution type");

  //hook to get solution details
  let { data, isLoading } = useGetSolutionAssets({
    solutionId: solutionsState.solutionId || "",
  });

  useEffect(() => {
    const solutionId = params.id;
    trackUserAction(userActivityEvents.PITCH_INTERNAL__PAGE_VIEWED, {
      id: solutionId,
    });
  }, []);

  useEffect(() => {
    if (params.id) {
      solutionsDispatch({
        type: SolutionsActionType.SET_SOLUTION_ID,
        payload: params.id,
      });
    } else {
      history.push("");
    }

    return () =>
      solutionsDispatch({
        type: SolutionsActionType.SET_SOLUTION_ID,
        payload: "",
      });
  }, [params]);

  useEffect(() => {
    if (solutionsState?.solutionObject?.id) {
      mutate(
        {
          solutionId: solutionsState.solutionObject?.id,
        },
        {
          onSuccess: (data: any) => {
            setShareableResObj(data.data);
          },
        }
      );
    }
    return () => setShareableResObj(initialShareableResObj);
  }, [solutionsState.solutionObject]);
  

  interface DataItem extends ISolutionAsset {
    alt: string;
    imageUrl: string;
  }

  //reducer function to segregate the raw data in

  function segregateByAssetType(data: ISolutionAsset[] = []) {
    return data.reduce(
      (acc: Record<string, { name: string; items: DataItem[] }>, item) => {
        const assetTypeKey = item.asset_type.key;
        const assetTypeName = item.asset_type.name;

        if (!acc[assetTypeKey]) {
          acc[assetTypeKey] = { name: assetTypeName, items: [] };
        }

        acc[assetTypeKey].items.push(item as DataItem);

        return acc;
      },
      {}
    );
  }

  useEffect(() => {
    if (data?.data) {
      setSegregatedData(segregateByAssetType(data?.data));
    }
  }, [data]);

  // console.log(segregatedData, "hello i am from segregated data");
  const componentMap: Record<
    string,
    React.FC<{ assets: any[]; name: string }>
  > = {
    carousel: CarouselComponent,
    image: ImageComponent,
    feature: SharedSolutionFeature,
    counter: CounterComponent,
    cards: CardComponent,
  };
  
  return (
    <div>
      {solutionAssetTypesData?.data.map((assetType) => {
        const assetGroup = segregatedData[assetType.key]; 
        const Component = componentMap[assetType.render_type]; 
        
        if (!Component || !assetGroup) return null; 

        return (
          <Component
            key={assetType.key}
            assets={assetGroup.items}
            name={assetGroup.name}
          />
        );
      })}
    </div>
  );
};
