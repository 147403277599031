import styled from "@emotion/styled";
// import {createGlobalStyle}  from "@emotion/styled";

export const HeaderTempletWrapper= styled.div`

.title-lineHeight{
  line-height: 64px;
}

/* landing page */
.li-square{
  width: 10px;
  height: 10px;
  background-color: #FF7964; 
  border-radius: 3px;
}
.hero-gradient {
  background: linear-gradient(
    to right,
    rgb(1, 42, 54),
    rgba(1, 42, 54, 0.9), 
    rgba(1, 42, 54, 0.8),
    rgba(1, 42, 54, 0.7),
    rgba(1, 42, 54, 0.5),
    rgba(1, 42, 54, 0.2)
  );
}


/* modile devices css */
@media (max-width: 480px) {
 
  .title-lineHeight {
    line-height: 30px;
  }
 
  .hero-gradient {
    background: linear-gradient(
      to right,
      rgb(1, 42, 54),
      rgba(1, 42, 54, 0.95),
      rgba(1, 42, 54, 0.9),
      rgba(1, 42, 54, 0.85),
      rgba(1, 42, 54, 0.75),
      rgba(1, 42, 54, 0.6)
    );
  }
 
  
}

.responsive-container {
  min-height: 100vh;
  height: auto;
}

@media(min-width:373px) and (min-width: 375px) and (max-width: 390px) and (min-height: 667px) and (max-height: 844px) {
  .responsive-container {
    min-height: 132vh;
  }
}

.ReadMoreHover:hover{
cursor:pointer;
color: #FF7964;
}

.ReadMoreText{
color: #FF7964;
}


` 
export const CardWrapper=styled.div`
.myCard {
 box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out; 
}

.myCard:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
}
`




export const FeatureSectionWrapper = styled.div`
  ul strong {
    font-size: 1.5rem;
    font-weight: 600;
  }

  p {
    color: #4b5563;
    margin-bottom: 1.5rem;
    line-height: 1.625;
    text-align: justify;
  }

  ul li {
    font-size: 1.8rem;
    text: bold;
    margin-bottom: 0.85rem;
    color: #4b5563;
  }

  @media screen and (min-width: 600px);
  ul strong {
    font-size: 1rem;
    font-weight: 500;
  }

  p {
    color: #4b5563;
    margin-bottom: 1.5rem;
    line-height: 1.625;
    text-align: justify;
    font-size:1.1 rem
  }
  ul li {
    font-size: 1.1rem;
    text: bold;
    margin-bottom: 0.75rem;
    color: #4b5563;
  }
`;


